<script lang="ts">
  export let label: string;
  export let name: string;
  export let secondaryLabel: string | undefined = undefined;
  export let showError: boolean | undefined = undefined;

  $: id = `select-${name}`;
</script>

<div class:error={showError}>
  <label class="text-base lg:text-lg block" for={id}>
    {label}
    {#if secondaryLabel}
      <span class="text-black text-opacity-50 ml-1">{secondaryLabel}</span>
    {/if}
  </label>
  <select
    class="text-base lg:text-lg h-12 border border-solid border-blackish mt-2 w-full px-4"
    {name}
    {id}
    on:change
    on:blur
  >
    <slot />
  </select>
</div>

<style>
  select {
    scroll-margin-top: 3em;
  }

  .error label {
    color: rgb(239, 68, 68);
  }

  .error select {
    border-color: rgb(239, 68, 68);
  }
</style>
