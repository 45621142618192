export function getCompanyNameForCompany(company) {
    switch (company) {
        case 'LifestyleMediapartner':
            return 'Lifestyle Mediapartner';
        default:
            return company;
    }
}
export function getSwitchboardForCompany(company) {
    switch (company) {
        case 'ButiksDisplay':
            return '+46 (0)10 195 90 35';
        default:
            return '+46 (0)16 551 26 00';
    }
}
export function getAddressForCompany(company) {
    switch (company) {
        case 'ButiksDisplay':
            return 'Portgatan 3, 633 42 Eskilstuna';
        case 'GolfDisplay':
            return 'S:t Olofsgatan 11A, 753 21 Uppsala';
        case 'GymDisplay':
        case 'LifestyleMediapartner':
        case 'UniversitetsMedia':
        case 'PadelDisplay':
            return 'Drottninggatan 4, 632 17 Eskilstuna';
        case 'StudentDisplay':
            return 'Vattengränden 2, 602 22 Norrköping';
        case 'YliopistoMedia':
            return 'Fredrikinkatu 61, 00100 Helsinki, Finland';
        default:
            return '';
    }
}
