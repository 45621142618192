<svg
  width="17"
  height="18"
  viewBox="0 0 17 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class={$$props.class}
>
  <path d="M15.3332 9H0.666504" stroke="white" stroke-width="2" />
  <path
    d="M8 16.3334L15.3333 9.00002L8 1.66669"
    stroke="white"
    stroke-width="2"
    stroke-linejoin="bevel"
  />
</svg>
