<script lang="ts">
  import Arrow from './Arrow.svelte';

  export let arrow: 'left' | 'right' | undefined;
  export let type: string = 'button';
</script>

<button
  on:click
  class="bg-blackish text-white h-12 px-8 inline-flex items-center text-base lg:text-lg"
  {type}
>
  {#if arrow === 'left'}
    <Arrow class="mr-12 transform origin-center rotate-180" />
  {/if}
  <span>
    <slot />
  </span>
  {#if arrow === 'right'}
    <Arrow class="ml-12" />
  {/if}
</button>
