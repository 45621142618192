<script lang="ts">
  export let label: string;
  export let name: string;
  export let placeholder: string;
  export let secondaryLabel: string | undefined = undefined;
  export let showError: boolean | undefined = undefined;

  $: id = `input-${name}`;
</script>

<div class:error={showError}>
  <label class="text-base lg:text-lg block" for={id}>
    {label}
    {#if secondaryLabel}
      <span class="text-black text-opacity-50 ml-1">{secondaryLabel}</span>
    {/if}
  </label>
  <input
    class="text-base lg:text-lg h-12 border border-solid border-blackish mt-2 block w-full px-4"
    type="text"
    {id}
    {name}
    {placeholder}
    on:change
    on:blur
  />
</div>

<style>
  input {
    scroll-margin-top: 3em;
  }

  input::placeholder {
    color: hsla(0, 0%, 0%, 0.2);
  }

  .error label {
    color: rgb(239, 68, 68);
  }

  .error input {
    border-color: rgb(239, 68, 68);
  }
</style>
