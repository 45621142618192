<svg
  width="64"
  height="64"
  viewBox="0 0 64 64"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class={$$props.class}
>
  <path d="M42.6667 0H21.3333L0 21.3333H21.3333L42.6667 0Z" fill="#1A1A1A" />
  <path
    opacity="0.5"
    d="M42.6668 0V21.3333L21.3335 42.6667V21.3333L42.6668 0Z"
    fill="#1A1A1A"
  />
  <path
    d="M64.0002 21.3333H42.6668L21.3335 42.6666H42.6668L64.0002 21.3333Z"
    fill="#1A1A1A"
  />
  <path
    opacity="0.5"
    d="M63.9998 21.3333V42.6666L42.6665 64V42.6666L63.9998 21.3333Z"
    fill="#1A1A1A"
  />
</svg>
