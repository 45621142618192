<script lang="ts">
  interface FormElements extends HTMLFormControlsCollection {
    company: HTMLSelectElement;
    name: HTMLInputElement;
    companyName: HTMLInputElement;
    role: HTMLInputElement;
    email: HTMLInputElement;
    phone: HTMLInputElement;
    mobile: HTMLInputElement;
    switchboard: HTMLInputElement;
    address: HTMLInputElement;
  }

  interface FormElement extends HTMLFormElement {
    readonly elements: FormElements;
  }

  import Button from './Button.svelte';
  import Input from './Input.svelte';
  import LogoIcon from './LogoIcon.svelte';
  import Select from './Select.svelte';
  import type { Company, InputName } from '../types';
  import {
    getAddressForCompany,
    getCompanyNameForCompany,
    getSwitchboardForCompany,
  } from '../utils';

  const companies: Company[] = [
    'ButiksDisplay',
    'GolfDisplay',
    'GymDisplay',
    'LifestyleMediapartner',
    'StudentDisplay',
    'UniversitetsMedia',
    'YliopistoMedia',
    'PadelDisplay',
  ];

  let formElement: HTMLFormElement;
  let validationErrors: InputName[] = [];

  const handleSubmit: svelte.JSX.EventHandler<SubmitEvent, FormElement> = (
    event
  ) => {
    const company = event.currentTarget.elements.company.value;
    const name = event.currentTarget.elements.name.value;
    const companyName = event.currentTarget.elements.companyName.value;
    const role = event.currentTarget.elements.role.value;
    const email = event.currentTarget.elements.email.value;
    const address = event.currentTarget.elements.address.value;

    validationErrors = [];

    if (!company) {
      validationErrors = [...validationErrors, 'company'];
    }
    if (!name) {
      validationErrors = [...validationErrors, 'name'];
    }
    if (!companyName) {
      validationErrors = [...validationErrors, 'companyName'];
    }
    if (!role) {
      validationErrors = [...validationErrors, 'role'];
    }
    if (!email) {
      validationErrors = [...validationErrors, 'email'];
    }
    if (!address) {
      validationErrors = [...validationErrors, 'address'];
    }

    if (validationErrors.length) {
      const firstKey = validationErrors[0];
      const firstInput = formElement.elements[firstKey] as HTMLInputElement;
      firstInput.focus();
      event.preventDefault();
      return false;
    }

    return true;
  };

  const handleBlur: svelte.JSX.FocusEventHandler<HTMLInputElement> = (
    event
  ) => {
    const { name, value } = event.currentTarget;

    if (validationErrors.includes(name as InputName) && !!value) {
      validationErrors = validationErrors.filter((key) => key !== name);
    }
  };

  const handleCompanyChange: svelte.JSX.ChangeEventHandler<
    HTMLSelectElement
  > = (event) => {
    const company = event.currentTarget.value as Company;
    const elements = formElement.elements as FormElements;

    elements.companyName.value = company
      ? getCompanyNameForCompany(company)
      : '';
    elements.switchboard.value = company
      ? getSwitchboardForCompany(company)
      : '';
    elements.address.value = company ? getAddressForCompany(company) : '';
  };
</script>

<div class="lg:flex lg:items-center lg:justify-center h-screen">
  <div class="space-y-12 lg:space-y-16 px-4 py-12 lg:py-20 lg:my-auto">
    <div>
      <div class="max-w-md">
        <h1 class="text-4xl lg:text-5xl leading-tight font-medium">
          Skapa e-postsignatur
        </h1>
        <p class="text-lg lg:text-xl leading-normal mt-4">
          Ange dina kontaktuppgifter i fälten intill och klicka sedan på skapa
          signatur.
        </p>
        <LogoIcon class="mt-6" />
      </div>
    </div>
    <div>
      <form
        class="w-full max-w-md"
        on:submit={handleSubmit}
        bind:this={formElement}
        action="/api/generate"
        method="post"
      >
        <div class="space-y-4">
          <Select
            name="company"
            label="Bolag"
            on:change={handleCompanyChange}
            showError={validationErrors.includes('company')}
          >
            <option value="">– Välj bolag –</option>
            {#each companies as company}
              <option value={company}
                >{company === 'LifestyleMediapartner'
                  ? 'Lifestyle Mediapartner'
                  : company}</option
              >
            {/each}
          </Select>
          <div class="h-px bg-black bg-opacity-20 my-6" />
          <Input
            on:blur={handleBlur}
            name="name"
            placeholder="Anna Andersson"
            label="Fullständigt namn"
            showError={validationErrors.includes('name')}
          />
          <Input
            on:blur={handleBlur}
            name="companyName"
            placeholder="Lifestyle Mediapartner"
            label="Bolagsnamn"
            showError={validationErrors.includes('companyName')}
          />
          <Input
            on:blur={handleBlur}
            name="role"
            placeholder="Key Account Manager"
            label="Titel"
            showError={validationErrors.includes('role')}
          />
        </div>
        <div class="h-px bg-black bg-opacity-20 my-6" />
        <div class="space-y-4">
          <Input
            on:blur={handleBlur}
            name="email"
            placeholder="anna.andersson@lifestylemedia.se"
            label="E-postadress"
            showError={validationErrors.includes('email')}
          />
          <Input
            on:blur={handleBlur}
            name="phone"
            label="Direktnummer"
            placeholder="123 456 78 90"
            secondaryLabel="(valfritt)"
          />
          <Input
            on:blur={handleBlur}
            name="mobile"
            label="Mobil"
            placeholder="123 456 78 90"
            secondaryLabel="(valfritt)"
          />
          <Input
            on:blur={handleBlur}
            name="switchboard"
            label="Växel"
            placeholder="123 456 78 90"
            secondaryLabel="(valfritt)"
            showError={validationErrors.includes('switchboard')}
          />
          <Input
            on:blur={handleBlur}
            name="address"
            placeholder="Drottninggatan 4, 632 20 Eskilstuna"
            label="Besöksadress"
            showError={validationErrors.includes('address')}
          />
        </div>
        <div class="mt-11">
          <Button arrow="right" type="submit">Skapa signatur</Button>
        </div>
      </form>
    </div>
  </div>
</div>
